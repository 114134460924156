import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styles from './contact.module.css'

import ContactForm from '../components/contact/contact-form'

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact"
      keywords={[
        `contact`,
        `yves de vrient contact`,
        'email',
        'formulier',
        'info',
        `Yves De Vrient`,
        `Loodgieter Yves De Vrient`,
        `De Vrient`,
        `Vrient`,
        `Loodgieter De Vrient`,
        `loodgieter`,
        `loodgieter Kruisem`,
        `loodgieter Zingem`,
        `Loodgieter Oudenaarde`,
      ]}
    />
    <h1>Contact</h1>
    <p className={styles.paragraph}>
      U kan ons contacteren via onderstaand formulier. De overige gegevens zijn
      hier ook te vinden.
    </p>

    {/* Netlify form */}
    {/* <form
      method="post"
      action="form.php"
      name="contact"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p className={styles.hidden}>
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
      </p>
      <input type="text" name="naam" placeholder="Naam" required />
      <input type="email" name="emailadres" placeholder="Emailadres" required />
      <input
        type="text"
        className={styles.onderwerp}
        name="onderwerp"
        placeholder="Onderwerp"
        required
      />
      <textarea
        name="bericht"
        cols="10"
        rows="5"
        placeholder="Typ hier uw email"
        required
      />
      <button type="submit" className={styles.submit__button}>
        Verstuur
      </button>
    </form> */}
    <ContactForm />
    <table>
      <tbody>
        <tr>
          <td>
            <strong>Telefoon:</strong>
          </td>
          <td>09 384 97 89 </td>
        </tr>
        <tr>
          <td>
            <strong>Adres:</strong>
          </td>
          <td>
            Kruishoutemsesteenweg 259 <br />
            9750 Kruisem (Zingem)
          </td>
        </tr>
        <tr>
          <td>
            <strong>Email:</strong>
          </td>
          <td>
            <a href="mailto:yves@yvesdevrient.be" style={{ color: `rgb(10, 103, 183)` }}>yves@yvesdevrient.be</a>
          </td>
        </tr>
      </tbody>
    </table>

    <iframe
      title="google-maps"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2515.8033062845107!2d3.6192952!3d50.9088555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c30d80d55f0715%3A0x4a52d95707cca033!2sDe+Vrient+%2F+Yves!5e0!3m2!1snl!2sbe!4v1440109494996"
      width="100%"
      height="450px"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen
    />

    <h2>Platte daken - Zink dakwerken</h2>
    <p className={styles.paragraph}>
      Heeft u hulp nodig voor Zink - Koper - Platte daken - Roofing - EPDM, dan
      kunt u terecht bij
      <strong> Nico De Vrient</strong>
    </p>

    <table>
      <tbody>
        <tr>
          <td>
            <strong>Adres:</strong>
          </td>
          <td>
            Wannegemstraat 9 <br />
            9750 Kruisem (Zingem){' '}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Email:</strong>
          </td>
          <td>
            <a href="mailto:nico@nicodevrient.be" style={{ color: `rgb(10, 103, 183)` }}>nico@nicodevrient.be</a>
          </td>
        </tr>
      </tbody>
    </table>
  </Layout>
)

export default ContactPage
