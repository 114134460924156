import React from 'react'

import { Form, withFormik, FastField, ErrorMessage } from 'formik'
import Recaptcha from 'react-google-recaptcha'
import * as Yup from 'yup'

import styles from './contact-form.module.css'

const ContactForm = ({
  setFieldValue,
  isSubmitting,
  values,
  errors,
  touched,
}) => (
  <Form
    name="yvesdevrient-contact-form"
    method="post"
    className={styles.container}
  >
    <input type="hidden" name="hidden" value="" />
    <div className={styles.field}>
      <FastField
        component="input"
        aria-label="naam"
        type="text"
        name="name"
        placeholder="Voor- en achternaam"
        error={touched.name && errors.name}
      />
      <ErrorMessage component="span" name="name" />
    </div>
    <div className={styles.field}>
      <FastField
        component="input"
        aria-label="email"
        type="email"
        name="email"
        placeholder="Email adres"
        error={touched.email && errors.email}
      />
      <ErrorMessage component="span" name="email" />
    </div>
    <div className={styles.field} style={{ gridColumn: '1/-1' }}>
      <FastField
        component="input"
        aria-label="onderwerp"
        type="text"
        className={styles.onderwerp}
        name="subject"
        placeholder="Onderwerp"
        error={touched.subject && errors.subject}
      />
      <ErrorMessage component="span" name="subject" />
    </div>
    <div className={styles.field} style={{ gridColumn: '-1/1' }}>
      <FastField
        aria-label="bericht"
        component="textarea"
        name="message"
        cols="10"
        rows="5"
        placeholder="Typ hier uw bericht..."
        error={touched.message && errors.message}
      />
      <ErrorMessage component="span" name="message" />
    </div>
    {values.name && values.subject && values.email && values.message && (
      <div style={{ margin: `5px 0 12px 0` }}>
        <FastField
          component={Recaptcha}
          sitekey="6LcmIbQUAAAAAOFHVmFuSljCHU7vERkZwvwtN5Ye"
          name="recaptcha"
          onChange={value => setFieldValue('recaptcha', value)}
        />
        <ErrorMessage component="span" name="recaptcha" />
      </div>
    )}
    {values.success && (
      <span className={styles.succes}>
        <p>
          Uw bericht is succesvol verzonden, u kunt binnenkort een antwoord
          verwachten.
        </p>
      </span>
    )}
    <button
      type="submit"
      className={styles.submit__button}
      disabled={isSubmitting}
    >
      Verstuur
    </button>
  </Form>

  // <form method="post" action="/form.php" name="contact">
  //   <input type="hidden" name="hidden" value="" />
  //   <input
  //     type="text"
  //     name="name"
  //     placeholder="Voor- en achternaam"
  //     required
  //   />
  //   <input
  //     type="email"
  //     name="email"
  //     placeholder="Email adres"
  //     required
  //   />
  //   <input
  //     type="text"
  //     className={styles.onderwerp}
  //     name="subject"
  //     placeholder="Onderwerp"
  //     required
  //   />
  //   <textarea
  //     name="message"
  //     cols="10"
  //     rows="5"
  //     placeholder="Typ hier uw bericht..."
  //     required
  //   />

  //   {/* <Recaptcha
  //       elementID="recaptcha"
  //       sitekey="6LcmIbQUAAAAAOFHVmFuSljCHU7vERkZwvwtN5Ye"
  //     /> */}

  //   <button type="submit" className={styles.submit__button}>
  //     Verstuur
  //     </button>
  // </form>
  //   );
)

export default withFormik({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    subject: '',
    message: '',
    recaptcha: '',
    succes: false,
  }),
  validationSchema: () =>
    Yup.object().shape({
      name: Yup.string().required('Volledige naam is verplicht'),
      subject: Yup.string().required('Onderwerp is verplicht'),
      email: Yup.string()
        .email('Ongeldige e-mail')
        .required('E-mail is verplicht'),
      message: Yup.string().required('Bericht is verplicht'),
      recaptcha: Yup.string().required('Robots zijn niet welkom!'),
    }),
  handleSubmit: async (
    { name, subject, email, message, recaptcha },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const encode = data => {
        return Object.keys(data)
          .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
          )
          .join('&')
      }
      // form.php located in the static folder
      await fetch('./form.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'yvesdevrient-contact-form',
          name,
          subject,
          email,
          message,
          hidden: '',
        }),
      })
      await setSubmitting(false)
      await setFieldValue('success', true)
      setTimeout(() => resetForm(), 12000)
    } catch (err) {
      setSubmitting(false)
      setFieldValue('success', false)
      alert('Er ging iets mis, heeft u een internetverbinding?') // eslint-disable-line
    }
  },
})(ContactForm)
